import { createSelector } from 'reselect';

const AuthSelector = state => state.auth;

const authSummary = (auth) => {
  const summary = { authenticated: false, email: '' };
  if (auth.authenticated) {
    summary.authenticated = auth.authenticated;
  }

  if (auth.email) {
    summary.email = auth.email;
  }

  return summary;
};

export default createSelector(
  AuthSelector,
  authSummary,
);
