export const GAMEBOT_RESULTS = 'frontend/gamebot/GAMEBOT_RESULTS';
export const GAMEBOT_STORE_HITS = 'frontend/gamebot/GAMEBOT_STORE_HITS';
export const GAMEBOT_STORE_FILTERS = 'frontend/gamebot/GAMEBOT_STORE_FILTERS';
export const GAMEBOT_START_OVER = 'frontend/gamebot/GAMEBOT_START_OVER';
export const GAMEBOT_RESET_SELECTION = 'frontend/gamebot/GAMEBOT_RESET_SELECTION';

const gameBotSelectionsLS = JSON.parse(localStorage.getItem('gameBotSelections')) || [];
const initialState = { results: [], selection: gameBotSelectionsLS };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GAMEBOT_RESULTS:
      return { ...state, ...action.payload };
    case GAMEBOT_STORE_HITS:
      return { ...state, ...action.payload };
    case GAMEBOT_STORE_FILTERS:
      return { ...state, ...action.payload };
    case GAMEBOT_START_OVER:
      return { results: [], selection: [] };
    case GAMEBOT_RESET_SELECTION:
      return { results: [], selection: [] };
    default:
      return state;
  }
}
